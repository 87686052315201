import { el, text, list } from 'redom';
//import { dispatch } from './dispatch';
import { AudioPlayer } from './player';
//import { Admin } from './admin';
//import { StreamList } from './estudios';
import { defaultStream, iframeUrl, player_streams } from './config';
import { ManyPlayers } from './manyplayers';



class Iframe {
  constructor () {
    let language = location.search.slice(1).split('=')[1];
    let url;
    if (  language !== undefined ) {
      url = iframeUrl[language.substr(0,2)];
    }
    if ( url  === undefined ) {
      url = iframeUrl.pt;
    }

    this.el = el('iframe', {
      src: url,
      height: "100%",
      frameborder: "0",
      allowfullscreen: "true",
      //
    })
  }
}

export class App {
  constructor () {
    this.el = el('.app',
      this.allPlayers = new ManyPlayers(),
      //el('#player',
      //  //this.frame = new Frame(),
      //  el('.player-container',
      //    this.player = new AudioPlayer(defaultStream),
      //  )
      //  //this.streamchooser = new StreamChooser(),
      //),
      //el('#frame',
      //  /*
      //  this.admintoggle = el('a.close', 'A', {
      //    title: 'ADMIN',
      //    href: 'javascript:void(0)',
      //    onclick: (e) => dispatch(this, 'toggleAdmin', true),
      //    style: 'display: none;'
      //  }),
      //  */
      //  this.frame = new Iframe(),
      //),
      /*
      el('#admin.hidden',
        this.admin = new Admin(),
      ),
      */
      el('#footer',
        el('.container',
          text('powered by OSSO.pt')
        )
      )
    );
  }
  /*
  onmount () {
    dispatch(this, 'checkStreams', true);
    this.intervalID = window.setInterval(() => {
      dispatch(this, 'checkStreams', '');
    }, 60000);
  }
  */
  /*
  update (data) {
    let { streams, admin } = data;
    if ( (admin !== undefined ) &&  ( admin !== this.isAdmin ) ){
      this.isAdmin = admin;
      if (this.isAdmin) {
        this.admintoggle.style.display = null;
      } else {
        this.admintoggle.style.display = 'none';
      }
    }
    if ( streams )  {
      //this.streamlist.update(streams);
      if ( this.isAdmin ) {
        this.admin.streamchooser.update(streams);
      }
    }
  }
  */
}
