import { el } from 'redom';
import { dispatch } from './dispatch';
import { AudioPlayer } from './player';
import { player_streams } from './config';


class OnePlayer {
    constructor (streamName, index) {
        this.streamName = streamName;
        this.index = index;
        this.playing = false;
        this.el = el('.one-player',
             el('.player-container', 
                 this.player = new AudioPlayer(streamName, (state) => {
                     dispatch(this, 'playerChanged', {index: this.index, state: state})
                 }),
             )
        )
    }
    update(state) {
        this.playing = ( state === 'play' );
    }
    play () {
        this.player.play(this.streamName);
        this.playing = true;
    }
    stop(){
        if (this.playing) {
            this.player._stop();
            this.playing = false;
        }
    }
}



export class ManyPlayers {
    constructor () {
        let listOfPlayers = [];
        for (let i=0; i < player_streams.length; i++) {
            var one_player = player_streams[i];
            listOfPlayers.push(new OnePlayer(one_player, i));
        }
        this.el = el('#many-players',
            this.listOfPlayers = listOfPlayers 
        )
    }
    update (data) {
        this.listOfPlayers[data.index].update(data.state);
        if (data.state == 'play') {
            for (let i=0; i<player_streams.length; i++) {
                if ( i != data.index ) {
                    this.listOfPlayers[i].stop();
                }
            }
        }
    }
    //play(index) {
    //    for (let i=0; i<player_streams.length; i++) {
    //        if ( i === index ) {
    //            this.listOfPlayers[i].play();
    //        } else {
    //            this.listOfPlayers[i].stop();
    //        }
    //    }
    //}
    stop(index) {
        this.listOfPlayers[index].stop();
    }
}
