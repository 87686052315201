import { listen } from './dispatch';
import Store from '@redom/store';
import { parseStreams, compareDates } from './utils';
import { rootUrl } from './config';

export const api = (app) => {
  const store = new Store();
  let updating;

  // Application logic:
  listen(app, {
    playerChanged( opts) {
      app.allPlayers.update(opts);
    },
    changeStream(stream) {
      //console.log('change stream');
      app.player.update(stream);
    },
    forceStream(stream) {
      //console.log('forcing stream: ' + stream);
      force_stream(stream);
    },
    checkStreams(streams) {
      //console.log('check streams');
      //check_streams(streams);
      //console.log('check nowplaying');
      check_nowplaying(streams);
    },
    playStream(stream) {
      /* Play estudios stream */
      app.player.play(stream);
    },
    schedule (scheduleRd) {
      get_schedule().then((data) => {
      });
    },
    editSchedule(schEl){
      if ( schEl === null ) {
        //console.log('form is empty');
        app.admin.form.update({});
      } else {
        app.admin.form.update(schEl.values());
      }

    },
    saveSchedule(formEl){
      add_schedule(formEl.values()).then((errors) => {
        if (errors.length) {
          console.log('form has errors');
        } else {
          formEl.hide();
        }
      });
    },
    deleteSchedule(formEl){
      //console.log('deleting');
      //console.log(formEl.values());
      formEl.hide();
    },
    setMessage(message){
      set_message(message);
    },
    toggleAdmin(toggle) {
      let frame = document.getElementById('frame');
      let admin = document.getElementById('admin');
      if (toggle) {
        frame.classList.add('hidden');
        admin.classList.remove('hidden');
      } else {
        frame.classList.remove('hidden');
        admin.classList.add('hidden');
      }
    }
  });

  const set = (path, value) => {
    store.set(path, value);
    updating || (updating = window.requestAnimationFrame(() => {
      updating = false;
      let data = {};
      data[path] = value;
      app.update(data);
    }));
  };

  const get_stream_list = () => {
    let timestamp = +new Date();
    return fetch('/streamList.txt?d=' + timestamp)
      .then((response) => { return response.text() })
      .then((data) => {
        let streams = parseStreams(data);
        store.set('streams', streams);
        app.admin.form.setChoices(streams);
        return streams;
      })
  }

  const parse_server_data = (data) => {
    let { streams, nowplaying, start, end, next, admin, schedule, message, errors, listeners } = data;
    let nowplaying_message;
    if (message) {
      nowplaying_message = nowplaying + ' ' + message;
    } else {
      nowplaying_message = nowplaying;
    }
    if ( (admin) && (listeners) ) {
      nowplaying_message = ' (' + listeners + ') ' + nowplaying_message;
    }
    store.set('message', message);
    store.set('nowplaying', nowplaying_message);
    store.set('start', start);
    store.set('end', end);
    store.set('next', next);
    store.set('admin', admin)

    app.player.nowplaying.update(store.get());

    let activeStreams = streams.split('\n').map((d) => {
      return rootUrl + d;
    });
    store.set('streams', store.get('streams', []).map((stream, index) => {
      stream.active = ( activeStreams.indexOf(stream.value) !== -1 )
      return stream;
    }));
    app.update(store.get());
    if ( admin ) {
      if (schedule) {
        app.admin.update(schedule);
      }
      if ( (message != app.admin.message.value) && ( document.activeElement !== app.admin.message) ) {
        app.admin.message.value = message;
      }
      if (errors) {
        return errors;
      } else {
        return [];
      }

    }
  }

  const check_nowplaying = (first_conn) => {
    let streamArray = store.get('streams');
    if (!streamArray) {
      //get_schedule();
      return get_stream_list().then((streams) => {
        return check_nowplaying();
      });
    } else {
      return fetch('/nowplaying').then((response) => {
        return response.json();
      }).then((data) => {
        parse_server_data(data);
      });
    }

  }


  /* ADMIN */
  const force_stream = (stream) => {
    return fetch('/admin/stream' + stream).then((response) => {
      return response.json();
    }).then((data) => {
      parse_server_data(data);
    }).catch((error) => {
      console.log('error starting stream ' + stream);
      console.log(error);
    });

  }

  const get_schedule = () => {
    return fetch('/admin/schedule').then((response) => {
      return response.json();
    }).then((data) => {
      parse_server_data(data);
    }).catch((error) => {
      console.log('error fetching schedule');
      console.log(error);
    });
  }

  const add_schedule = (data) => {
    return fetch('/admin/schedule', {
      method: 'POST',
      body: JSON.stringify(data)
    }).then((response) => {
      return response.json();
    }).then((data) => {
      return parse_server_data(data);
      //app.admin.update(data.schedule);
    }).catch((error) => {
      console.log('error fetching schedule');
      console.log(error);
    });
  }

  const set_message = (message) => {
    message = ( message ) ? message : '';
    return fetch('/admin/message', {
      method: 'POST',
      body: JSON.stringify({message: message})
    }).then((response) => {
      return response.json();
    }).then((data) => {
      return parse_server_data(data);
    });
  }
};
