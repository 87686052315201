import { el, list, text } from 'redom';
import { defaultStream, defaultEncoding } from './config';

/*
 * AudioPlayer
 */

export class AudioPlayer {
  constructor(srcUrl, stateChangedCallback) {
    this.activeStream = false;
    this.stateChangedCallback = stateChangedCallback;
    this.playing = false;
    this.streamTitle = srcUrl;
    this.defaultUrl = (srcUrl === undefined) ? defaultStream : srcUrl;
    let timestamp = +new Date();
    this.hide = (className) => {
      const show = (className == 'play') ? 'stop' : 'play';
      let hideEls = this.el.getElementsByClassName(className);
      let showEls = this.el.getElementsByClassName(show)
      for (var i = 0, l = hideEls.length; i < l; i++) {
        var v = hideEls[i];
        v.style.display = 'none';
      }
      for (var i = 0, l = showEls.length; i < l; i++) {
        var v = showEls[i];
        v.style.display = null;
      }
    }
    this.errorHandler = (e) => {
      console.log(e);
      this.hide('stop');
    }


    this.el = el('.player',
      el('.audio-container.center',
        el('.audio',
          this.audio = el('audio', {
              onended: (e) => {
                this.hide('stop');
              },
              onpause: (e) => {
                this.hide('stop');
              },
              onplay: (e) => {
                this.hide('play');
              }
            },
            this.source = el('source', {
              src: '',
              onerror: (e) => {
                this.hide('stop');
              },
            })
          ),
          el('.sPlay.play', {
            onclick: e => {
              if ( this.audio.paused ) {
                this.playing = true;
                this._play();
              }
            }
          }),
          el('.sStop.stop', {
            onclick: e => {
              this.playing = false;
              this._stop();
            },
            style: 'display: none;',
          }),
        ),
      ),
      el('.img-container',
        this.logo = el('img', {
          src: 'images/player_logo.png'
        }),
        //el('p', this.streamTitle.match(/.*\/([^\/]*\.mp3)/)[1])
        el('p', this.streamTitle.match(/.*\/([^\/]*\.mp3)/)[1].replace('canal', 'stream ').replace('.mp3', '').toUpperCase())
      )
    )
  }
  update(data){
    if (data) {
      this._play(data);
    }
  }
  _stateChanged(state){
    //console.log('_stateChanged');
    //console.log(state);
    if (typeof this.stateChangedCallback === 'function') {
      this.stateChangedCallback(state);
    }
  }
  _play(src) {
    if ( ! src ) {
      src = this.defaultUrl;
    };
    let typ = src.substr(src.length -3);
    if ( ( typ != 'mp3') && ( typ != 'ogg') ) {
      return;
    }
    this.source.type = 'audio/' + typ;
    let timestamp = +new Date();
    this.source.src = src + '?n=' + timestamp;
    this.audio.load();
    this.audio.play();
    this._stateChanged('play');
  }
  _stop() {
    this.source.type = '';
    this.source.src = '';
    this.audio.load();
    this._stateChanged('stop');
  }
  play(stream) {
    if ( stream ) {
      this._play(stream.value);
      return;
    }
    if (this.playing) {
      this._play();
      return;
    }
    this._stop();
  }
}
