const config = {
  defaultStream: 'https://osso.pt/_play.mp3',
  defaultEncoding: 'mp3',
  rootUrl: '',
  iframeUrl: {
    en: 'https://www.osso.pt/en/um-player-osso-en',
    pt: 'https://www.osso.pt/um-player-osso'
  },
  logoImage: "images/player_logo.png",
  player_streams: [
    "https://stream.osso.pt/canal7.mp3",
    "https://stream.osso.pt/canal8.mp3",
    "https://stream.osso.pt/canal9.mp3",
    "https://stream.osso.pt/canal10.mp3",
    "https://stream.osso.pt/canal11.mp3",
    "https://stream.osso.pt/canal12.mp3",
  ]
}


export const defaultStream = config.defaultStream;
export const defaultEncoding = config.defaultEncoding;
export const rootUrl = config.rootUrl;
export const iframeUrl = config.iframeUrl;
export const oggStreams = config.oggStreams;
export const logoImage = config.logoImage;
export const player_streams = config.player_streams;


